<template>
  <div class="rewardBox">
    <el-dialog title="" :visible.sync="ifshow" center>
      <div class="box">
        <div class="text">
          要删除此视频吗？删除视频后你可以在回收箱查看该视频。
        </div>
      </div>
      <div class="boxIte">
        <slot> </slot>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clackOk()">确认删除</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Store from "../../../store";
export default {
  props: {
    it: {
      type: Object,
    },
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      // 是否显示
      ifshow: false,
      act: "10",
      // 是否打开输入
      inputShow: false,
      web3: "",
      shangCont: "",
    };
  },
  methods: {
    up() {
      this.ifshow = true;
    },
    down() {
      this.ifshow = false;
    },
    clackOk() {
      console.log("132");
      this.down();
      this.$emit("clackok");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  /* 矩形 20 */

  border-radius: (10 / 3.75vw);
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
  /* 矩形 20 */
  width: 100%;
  width: (339 / 3.75vw);
  box-sizing: border-box;
  // padding-bottom: 30px;
  // height: 711px;
  .el-button {
    width: 100%;
    height: (42 / 3.75vw);
    font-size: (14 / 3.75vw);
    background-color: #ff7d41;
    border: none;
  }
  .hui {
    background-color: #999;
  }
}
.text {
  /* 要删除此视频吗？删除视频后你可以在回收箱查看该视频。 */

  // height: 35px;
  font-family: SourceHanSansCN-Regular;
  font-size: (12 / 3.75vw);
  font-weight: normal;
  letter-spacing: 0em;
  color: #333333;
}
.boxIte {
  margin-top: (10 / 3.75vw);
  display: flex;
  justify-content: center;
}
</style>
