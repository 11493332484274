<template>
  <div class="viewBox">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="first"> </el-tab-pane>
      <el-tab-pane label="审核中" name="second"> </el-tab-pane>
      <el-tab-pane label="未通过" name="third"> </el-tab-pane>
      <el-tab-pane label="已删除" name="third4"> </el-tab-pane>
    </el-tabs>
    <div class="videoList">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getMyVideo()"
        class="act-van-list"
      >
        <item-box
          :it="item"
          v-for="item in store.myVideoList"
          :key="item.id"
          :type="Number(act)"
          @updete="upDt()"
        ></item-box>
      </van-list>
    </div>
  </div>
</template>
<script>
import Store from "../../../store";
import ItemBox from "./itemBox.vue";
export default {
  components: { ItemBox },
  mounted() {
    // this.getMyVideo();
    this.store.myVideoList = [];
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      loading: false,
      finished: false,
      activeName: "first",
      act: 0,
      select: "",
      total: 0,
      pageNum: 1,
      pageSize: 2,
    };
  },
  methods: {
    // 选中了某一项
    handleClick(tab) {
      console.log(tab.index);
      this.act = tab.index;
      this.pageNum = 1;
      this.store.myVideoList = [];
      this.finished = false;
      this.loading = true;
      this.getMyVideo();
    },
    // 获取我的视频
    async getMyVideo() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post("/userInfo/videos", {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: this.act,
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      // this.store.myVideoList = res.data.records;
      // this.total = Number(res.data.total);
      this.loading = false;
      this.total = Number(res.data.total);
      // this.list = res.data.records;
      this.store.myVideoList = this.uti.addArr(
        this.store.myVideoList,
        res.data.records
      );
      // 如果超出就
      if (this.pageNum * this.pageSize >= this.total) {
        this.finished = true;
      } else {
        this.pageNum++;
      }
    },
    upDt() {
      this.store.myVideoList = [];
      this.finished = false;
      this.loading = true;
      this.getMyVideo();
    },
  },
};
</script>

<style lang="less" scoped>
.viewBox {
  .actList {
    display: flex;
  }
  .videoList {
    margin-bottom: (50 / 3.75vw);
  }
}
/deep/ .el-tabs__item.is-top {
  background-color: #f1f1f1;
  border-radius: (5 / 3.75vw);
  padding: 0 (10 / 3.75vw);
  margin: (5 / 3.75vw);
  height: (30 / 3.75vw);
  line-height: (30 / 3.75vw);
}
/deep/ .el-tabs__nav-wrap::after {
  display: none;
}
/deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: (10 / 3.75vw);
}
/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: (10 / 3.75vw);
  margin-left: 0;
}
/deep/ .el-tabs__active-bar {
  display: none;
}
</style>
