<template>
  <div class="itemBox">
    <div class="ind" @click="goVideoDec()">
      <div class="start" v-if="it.authStatus == 2 || it.authStatus == 0">
        <svg
          v-if="it.authStatus == 0"
          class="icon svg-icon in"
          aria-hidden="true"
        >
          <use xlink:href="#icon-shenhezhong"></use>
        </svg>
        <!-- 未通过 -->
        <svg
          v-if="it.authStatus == 2"
          class="icon svg-icon err"
          aria-hidden="true"
        >
          <use xlink:href="#icon-weitongguo"></use>
        </svg>
      </div>
      <img :src="it.videoThumbnail" alt="" />
      <div class="ft">
        <!-- 点赞量： -->
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-dianzan1"></use>
        </svg>
        <div class="it">
          {{ it.loveNum }}
        </div>
        <!-- 播放量 -->
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-guankan"></use>
        </svg>
        <div class="it">
          {{ it.playNum }}
        </div>
      </div>
    </div>
    <div class="dec">
      <div class="p">
        {{ it.videoTitle }}
      </div>
      <div class="ft">
        <div class="l">
          <!-- <div class="item">播放量：{{ it.playNum }}</div>
          <div class="item">点赞量：{{ it.loveNum }}</div>
          <div class="item">打赏收入：{{ it.rewardNum }}</div> -->
          <div class="time">上传时间：{{ uti.changeTime(it.createTime) }}</div>
        </div>
        <div class="r">
          <!-- <div class="item">
            <svg @click="addLove()" class="sc icon svg-icon" aria-hidden="true">
              <use xlink:href="#icon-shoucang"></use>
            </svg>
            <svg @click="addLove()" class="sc icon svg-icon" aria-hidden="true">
              <use xlink:href="#icon-shoucang_shixin"></use>
            </svg>
          </div> -->
          <div class="item">
            <svg
              @click.stop="updateVideo()"
              v-if="type == 3"
              class="icon svg-icon"
              aria-hidden="true"
            >
              <use xlink:href="#icon-yihuifu-xiugai"></use>
            </svg>
            <svg
              @click.stop="upDelet()"
              v-else
              class="icon svg-icon"
              aria-hidden="true"
            >
              <use xlink:href="#icon-shanchu"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <delete ref="del" @clackok="deletVideo()">
      <!-- <box-2-item :it="it"></box-2-item> -->
      <video-box :it="it"></video-box>
    </delete>
  </div>
</template>

<script>
import Store from "../../../store";
import VideoBox from "../../home/video-box.vue";
// import Box2Item from "../../home/box2-item.vue";
import Delete from "./delete.vue";
export default {
  components: {
    Delete,
    VideoBox,
    //  Box2Item
  },

  mounted() {},
  props: {
    it: {
      type: Object,
    },
    type: {
      type: Number,
    },
  },

  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {
    upDelet() {
      this.$refs.del.up();
    },
    // 删除视频
    async deletVideo() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(
        "/videoInfo/video/updateDelete",
        {
          userId: this.store.user.userId,
          videoId: this.it.id,
        }
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.$emit("updete");
      return this.$message.success("删除成功");
    },
    // 恢复视频
    async updateVideo() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(
        "/videoInfo/video/relieveDelete",
        {
          userId: this.store.user.userId,
          videoId: this.it.id,
        }
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }

      this.$emit("updete");
      return this.$message.success("视频已经恢复");
    },
    // 前往视频详情
    goVideoDec() {
      this.store.actVideoId = this.it.id;
      this.$router.push("/mVideoDec");
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  /* 矩形 14 */
  margin-top: (10 / 3.75vw);
  display: flex;
  /* 矩形 6 */
  width: 100%;
  // height: 170px;
  border-radius: (10 / 3.75vw);
  background: #ffffff;
  box-shadow: 0px 4px (10 / 3.75vw) 0px rgba(153, 153, 153, 0.3);
  .ind {
    width: (150 / 3.75vw);
    height: (102 / 3.75vw);
    border-radius: (6 / 3.75vw);
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 65%,
      #000000 100%,
      #000000 100%
    );
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      transform: scaleY(1.35);
    }
    .start {
      font-size: (100 / 3.75vw);
      position: absolute;
      width: 100%;
      text-align: center;
      background-color: #0006;
      height: 100%;
      z-index: 20;
      svg {
        margin: 0 auto;
      }
      .in {
        color: rgba(255, 255, 255, 0.795);
      }
      .err {
        color: #ff3a3ad7;
      }
    }
    .time {
      position: absolute;
      bottom: 12px;
      right: 13px;
      /* 34:16 */
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #ffffff;
    }
    .ft {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 9;
      color: #fff;
      display: flex;
      align-items: center;
      padding: (3 / 3.75vw) (5 / 3.75vw);
      font-size: (8 / 3.75vw);
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.76),
        rgba(0, 0, 0, 0.233)
      );
      .it {
        margin-right: 16px;
        margin-left: 5px;
      }
    }
  }
  .dec {
    flex: 1;
    padding: 10px;

    height: 102px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .p {
      /* 稳定币发展历程稳定币发展历... */

      width: 172px;
      height: 52px;
      font-family: PingFang SC Bold;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #333333;
      overflow: hidden;

      text-overflow: ellipsis;

      display: -webkit-box;

      -webkit-box-orient: vertical;

      -webkit-line-clamp: 2;
    }
    .time {
      height: 17px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      margin-bottom: 16px;
    }
    .ft {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      height: 17px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      .l {
        flex: 1;
        display: flex;
        .item {
          margin-right: 53px;
          font-size: 16px;
        }
      }
      .r {
        // display: flex;
        // display: inline-flex;
        .item {
          margin-left: 8px;
          font-size: 14px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
